import * as React from "react"

const Offer = () => {
  return (
    <div id="offer">
      <h1>Services & prices</h1>
      <div className="offer-section">
        <div className="circle1 semi-circle">
          <h2>Lash Lift</h2>
          <div className="wrap">
            <p>Lash Lift + „Vitamin LashBotox”</p>
            <p>$70</p>
          </div>
          <div className="wrap">
            <p>Lash Lift + Tint + „Vitamin LashBotox”</p>
            <p>$80</p>
          </div>
          <div className="wrap">
            <p>Lower Lashes - additional</p>
            <p>$20</p>
          </div>
        </div>
        <div className="circle2 semi-circle">
          <h2>Lash Extensions</h2>
          <div className="wrap">
            <p>1:1 Classic</p>
            <p>$120</p>
          </div>
          <div className="wrap">
            <p>2-3D Light Volume</p>
            <p>$160</p>
          </div>
          <div className="wrap">
            <p>4-6D Volume</p>
            <p>$180</p>
          </div>
        </div>
        <div className="circle3 semi-circle">
          <h2>Brow Lamination</h2>
          <div className="wrap">
            <p>Brow Lamination</p>
            <p>$70</p>
          </div>
          <div className="wrap">
            <p>Brow Lamination + Tint</p>
            <p>$80</p>
          </div>
        </div>
        <div className="circle4 semi-circle">
          <h2>Hybrid Manicure</h2>
          <div className="wrap">
            <p>One Color</p>
            <p>$40</p>
          </div>
          <div className="wrap">
            <p>Ombre / Baby Boomer / French</p>
            <p>$50</p>
          </div>
          <div className="wrap">
            <p>Designs - starting at</p>
            <p>$5</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offer
